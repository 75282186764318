
class ImageUtils {
  getResolution(url) {
    return new Promise((resolve, reject) => {
      let image = new Image()
      image.onabort = (e) => {
        reject(e)
      }
      image.onerror = (e) => {
        reject(e)
      }
      image.onload = function () {
        resolve(this) //this = the image
      }
      image.src = url
    })
  }

  blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()

      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.onerror = (err) => {
        reject(err)
      }
      reader.onabort = (err) => {
        reject(err)
      }

      reader.readAsDataURL(blob)
    })
  }

  getImageRatioInfo(images) {

    let ratiosMap = {}

    let largestRatio = 0

    for(let i of images) {
      if(i.imageRatio) {

        if(i.imageRatio > largestRatio) {
          largestRatio = i.imageRatio
        }

        if(!ratiosMap[i.imageRatio]) {
          ratiosMap[i.imageRatio] = 0
        }
        ratiosMap[i.imageRatio]++
      }
    }

    let mostCommonRatio = null
    let count = 0

    for(let ratio in ratiosMap) {
      let ratioCount = ratiosMap[ratio]
      if(ratioCount > count) {
        count = ratioCount
        mostCommonRatio = ratio
      }
    }

    return {
      mostCommonRatio,
      largestRatio
    }
  }
}

export default (new ImageUtils())