import E2EEHelperCache from "./e2ee/E2EEHelperCache"
import ImageUtils from "./ImageUtils"

/**
 * We removed the cache--it wasn't giving the performance gains to justify it.
 */
class ImageCache {

  async destroy() {
    // eslint-disable-next-line no-console
    console.log('destroying blob cache...')
  }

  async getImage(accountId, deviceId, timestamp, url) {
    return await this._getImage(accountId, deviceId, timestamp, url)
  }

  async _getImage(accountId, deviceId, timestamp, url) {
    let imageData = await fetch(url)
    if(imageData.statusCode === 403) {
      throw new Error('Your credentials have expired, please reload the page.')
    }
    let dataBlob = await imageData.blob()
    let e2eeHelper = E2EEHelperCache.getE2EEHelper(accountId)

    let decryptedBlob = await e2eeHelper.decryptImage(deviceId, dataBlob, timestamp)
    let base64 = await ImageUtils.blobToBase64(decryptedBlob)
    // this.addBlob(fileKey, base64)
    return base64
  }
}

export default (new ImageCache())