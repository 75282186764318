<template>
  <v-dialog v-model="isVisible" :fullscreen="true">
    <div
      style="background: black; position: relative;"
      class="fill-height"
      v-touch="{
      left: () => swipeNext(),
      right: () => swipePrevious(),
      up: () => swipeNext(),
      down: () => swipePrevious()
    }"
    >

        <v-toolbar
          v-show="!hideInfo"
          dense
          flat
          color="rgba(0, 0, 0, 0.25)"
          style="position: absolute; top: 0%; width: 100%; z-index: 50;">
          <span class="white--text subtitle-1" style="opacity: 0.75;">{{`${currentIndex + 1} / ${images.length}`}}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!$store.state.inApp"
            text
            dark
            @click="download"
            :disabled="!$refs.photoGalleryImage || !$refs.photoGalleryImage.imageSource"
            style="opacity: 0.75;">
            <v-icon>cloud_download</v-icon>
          </v-btn>
          <v-btn dark text @click="onClose" style="opacity: 0.75;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>


<!--        <div id="mainImageContainer"-->
<!--             @click="imageClick"-->
<!--             style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"-->
<!--             v-if="showDelay"-->
<!--        >-->


      <div id="mainImageContainer"
           style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
           @click="toggleHideInfo"
           v-if="showDelay"
      >
<!--        <transition name="slide-fade-enter-active">-->
<!--        <v-slide-x-transition>-->
<!--        <transition-group tag="div" class="img-slider" name="slide">-->
<!--          <encrypted-image-->
<!--            :key="images[currentIndex - 1].fileKey"-->
<!--            v-if="currentIndex - 1 >= 0"-->
<!--            :image="images[currentIndex - 1]"-->
<!--            :min-height="50"-->
<!--            :account-id="accountId"-->
<!--            :device-id="deviceId"-->
<!--          ></encrypted-image>-->

        <transition name="slide">
          <encrypted-image
            ref="photoGalleryImage"
            :image="images[currentIndex]"
            :min-height="50"
            :account-id="accountId"
            :device-id="deviceId"
          ></encrypted-image>
        </transition>
<!--          <encrypted-image-->
<!--            :key="images[currentIndex + 1].fileKey"-->
<!--            v-if="currentIndex + 1 <= images.length - 1"-->
<!--            :image="images[currentIndex + 1]"-->
<!--            :min-height="50"-->
<!--            :account-id="accountId"-->
<!--            :device-id="deviceId"-->
<!--          ></encrypted-image>-->
<!--        </transition-group>-->
<!--        </v-slide-x-transition>-->
<!--        </transition>-->
      </div>

<!--      <v-carousel-->
<!--        :cycle="false"-->
<!--        :continuous="false"-->
<!--        height="100%"-->
<!--        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"-->
<!--      >-->
<!--        <v-carousel-item v-for="(image, index) in images" :key="image.fileKey">-->
<!--          <encrypted-image-->
<!--            ref="photoGalleryImage"-->
<!--            :image="image"-->
<!--            :min-height="50"-->
<!--            :account-id="accountId"-->
<!--            :device-id="deviceId"-->
<!--            :show-arrows="true"-->
<!--            :thumbnail="false"-->
<!--          ></encrypted-image>-->
<!--        </v-carousel-item>-->
<!--      </v-carousel>-->

      <v-fade-transition>
        <div id="controlsContainer" style="width: 100%; height: 100%;" v-show="!hideInfo">
          <v-btn
            @click="previous"
            icon
            style="position: absolute; left: 2%; top: 50%; z-index: 50; opacity: 0.5;"
          >
            <v-icon x-large color="accent">
              chevron_left
            </v-icon>
          </v-btn>

<!--          <v-icon outlined color="accent" v-if="images[currentIndex] && images[currentIndex].encrypted" style="position: absolute; right: 1vw; bottom: 1vh;">-->
<!--            key-->
<!--          </v-icon>-->

          <span v-if="images[currentIndex] && images[currentIndex].encrypted" style="position: absolute; right: 1vw; bottom: 1vh;">{{keyEmoji}}</span>

          <v-btn
            @click="next"
            icon
            style="position: absolute; right: 2%; top: 50%; z-index: 50; opacity: 0.5;">
            <v-icon x-large color="accent">
              chevron_right
            </v-icon>
          </v-btn>
        </div>
      </v-fade-transition>

      <div
        v-if="!hideInfo"
        class="white--text caption pl-2"
        style="position: absolute; bottom: 1%; opacity: 0.5; word-wrap: normal; text-overflow: ellipsis; background: black; width: 100%;"
      >
        <v-fade-transition>
          <div v-if="images[currentIndex] && images[currentIndex].deviceName">
            {{images[currentIndex].deviceName}}
          </div>
        </v-fade-transition>
        <v-fade-transition>
          <div v-if="appDetails && showDelay">
            {{appDetails}}
          </div>
        </v-fade-transition>
        <v-fade-transition>
          <div v-if="appName && showDelay">
            {{appName}}
          </div>
        </v-fade-transition>
        <v-fade-transition>
          <div v-if="images[currentIndex] && images[currentIndex].riskReason && showDelay" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            {{images[currentIndex].riskReason || 'notProvided'}}
          </div>
        </v-fade-transition>
        <div v-if="images[currentIndex] && images[currentIndex].takenAt">
          {{timestampString}}
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
  import { highRisk} from "../../risk";
  import EncryptedImage from "../e2ee/EncryptedImage";
  import E2EEHelperCache from "../../services/e2ee/E2EEHelperCache";
  import Constants from "../../Constants";
  import mobileBackButtonMixin from "../../mixins/mobileBackButtonMixin";

  let lastClick = 0
  let delayShowingTimeout = null

  export default {
    name: "PhotoGallery",
    mixins: [
      mobileBackButtonMixin('onClose')
    ],
    components: {EncryptedImage},
    data() {
      return {
        keyEmoji: Constants.keyEmoji,
        slidingPrevious: false,
        hideInfo: false,
        currentIndex: -1,
        highRisk: highRisk,
        showDelay: true
      }
    },
    props: {
      accountId: {
        type: String,
        default: null
        // required: true
      },
      deviceId: {
        type: String,
        default: null
        // required: true
      },
      visible: {
        type: Boolean,
        default: false
      },
      images: {
        type: Array,
        default: () => []
      },
      openIndex: {
        type: Number,
        default: -1
      }
    },
    methods: {
      toggleHideInfo(event) {
        if(Date.now() - lastClick <= 500) {
          console.log('toggle hide info')
          this.hideInfo = !this.hideInfo

          //double clicking was causing the screenshot info to be selected
          if(document.selection && document.selection.empty) {
            document.selection.empty();
          } else if(window.getSelection) {
            let sel = window.getSelection();
            sel.removeAllRanges();
          }

        }
        lastClick = Date.now()
      },
      //delayShowing prevents us from attempting to decrypt every single image if the user swipes/scrolls/presses arrow key
      //  super duper fast.  This is most common with the arrow key.
      delayShowing() {
        if(delayShowingTimeout) {
          this.showDelay = false
          clearTimeout(delayShowingTimeout)
        }
        delayShowingTimeout = setTimeout(() => {
          this.showDelay = true
        }, 200)
      },
      swipeNext() {
        console.log('swipeNext')
        console.log(this.$refs.photoGalleryImage.$refs.pinchZoom.ivyPinch.scale)
        if(this.$refs.photoGalleryImage.$refs.pinchZoom) {
          if(this.$refs.photoGalleryImage.$refs.pinchZoom.ivyPinch.scale === 1.0) {
            this.next()
          }
        } else {
          this.next()
        }
      },
      swipePrevious() {
        console.log('swipePrevious')
        console.log(this.$refs.photoGalleryImage.$refs.pinchZoom.ivyPinch.scale)
        if(this.$refs.photoGalleryImage.$refs.pinchZoom) {
          if(this.$refs.photoGalleryImage.$refs.pinchZoom.ivyPinch.scale === 1.0) {
            this.previous()
          }
        } else {
          this.previous()
        }
      },
      next() {
        this.slidingPrevious = false
        console.log('next ' + this.currentIndex)
        this.delayShowing()
        this.currentIndex = Math.min(this.currentIndex + 1, this.images.length - 1)
      },
      previous() {
        this.slidingPrevious = true
        console.log('previous')
        this.delayShowing()
        this.currentIndex = Math.max(this.currentIndex - 1, 0)
      },
      async download() {

        if(!this.$refs.photoGalleryImage.imageSource) {
          return
        }

        const request = new Request(this.$refs.photoGalleryImage.imageSource)

        let response = await fetch(request)
        let blobby = await response.blob()

        let a = document.createElement('a')
        a.href = URL.createObjectURL(blobby)
        a.setAttribute("download", this.timestampString + '.jpg')
        a.click();
        URL.revokeObjectURL(a.href)
      },
      onClose() {
        this.$emit('on-close')
      },
      keyFunction(e) {
        if(e.key === 'ArrowLeft') {
          this.previous()
        } else if(e.key === 'ArrowRight') {
          this.next()
        }
        e.stopPropagation()
      }
    },
    computed: {
      timestampString() {
        return new Date(this.images[this.currentIndex].takenAt).toLocaleDateString() + ' ' + new Date(this.images[this.currentIndex].takenAt).toLocaleTimeString()
      },
      isVisible: {
        get() {
          return this.visible
        },
        set() {

        }
      }
    },
    asyncComputed: {
      async appName() {
        if(!this.showDelay) {
          return ''
        }
        if(this.currentIndex < 0 || this.currentIndex >= this.images.length) {
          return ''
        }
        if(!this.images[this.currentIndex]) {
          return ''
        }
        if(!this.images[this.currentIndex].appName) {
          return ''
        }
        if(!this.images[this.currentIndex].encrypted) {
          return this.images[this.currentIndex].appName
        }

        try {
          let e2eeHelper = E2EEHelperCache.getE2EEHelper(this.accountId)

          let ret = await e2eeHelper.decryptString(this.deviceId, this.images[this.currentIndex].appName, this.images[this.currentIndex].takenAt)
          if(ret.length > 100) {
            ret = ret.substring(0, 100)
            ret += '...'
          }
          return ret
        } catch(e) {
          if(e.name) {
            let ret = `Error Decrypting: ${e.name}`
            if(e.message) {
              ret += `: ${e.message}`
            }
            return ret
          }
          return e
        }
      },
      async appDetails() {
        if(!this.showDelay) {
          return ''
        }
        if(this.currentIndex < 0 || this.currentIndex >= this.images.length) {
          return ''
        }
        if(!this.images[this.currentIndex]) {
          return ''
        }
        if(!this.images[this.currentIndex].appDetails) {
          return ''
        }
        if(!this.images[this.currentIndex].encrypted) {
          return this.images[this.currentIndex].appDetails
        }

        try {
          let e2eeHelper = E2EEHelperCache.getE2EEHelper(this.accountId)
          let ret = await e2eeHelper.decryptString(this.deviceId, this.images[this.currentIndex].appDetails, this.images[this.currentIndex].takenAt)
          if(ret.length > 100) {
            ret = ret.substring(0, 100)
            ret += '...'
          }
          return ret
        } catch(e) {
          if(e.name) {
            let ret = `Error Decrypting: ${e.name}`
            if(e.message) {
              ret += `: ${e.message}`
            }
            return ret
          }
          return e
        }
      }
    },
    beforeMount() {
      this.currentIndex = this.$props.openIndex
      console.log('before mount')
      window.addEventListener('keydown', this.keyFunction)
    },
    beforeDestroy() {
      console.log('before destroy')
      window.removeEventListener('keydown', this.keyFunction)
    },
    mounted() {
      this.currentIndex = this.openIndex
    },
    watch: {
      openIndex: function (newValue) {
        console.log(`openIndex = ${newValue}`)
        if(newValue !== -1) {
          this.currentIndex = newValue
        }
      }
    }
  }
</script>

<style scoped>
  .slide-leave-active,
  .slide-enter-active {
    transition: 1s;
  }
  .slide-enter {
    transform: translate(100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }

  .img-slider{
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 400px;
  }

  .img-slider img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right:0;
  }
</style>