class Constants {
  constructor() {
    this.titleBarHeight = 56;
    this.keyEmoji = '🔑'
    this.unknownEmoji = '❔'
    this.newEmoji = '' //'🆕'
    this.insufficientSubscriptionUrl = `${process.env.VUE_APP_HOST}/f11bdc24-fa5d-433f-897d-95ffdd83e48e.jpg`
    this.insufficientSubscriptionImageRatio = 1.426136
    this.highRisk = 'high-risk'
  }
}

export default (new Constants())