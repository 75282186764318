/* eslint-disable no-console */
/**
 * Allows mobile back button to close dialogs.
 *
 * In order for this to work, dialogs **must** be v-if'd and destroyed
 * when closed.  That's what causes it to unregister.
 * @param onCloseFunction
 * @returns {{created(): void, beforeDestroy(): void}}
 */

import MobileBackButtonHelper from "../services/MobileBackButtonHelper";
import AndroidInterface from "../services/AndroidInterface";

export default function(onCloseFunction) {

  onCloseFunction = onCloseFunction || 'onClose'

  let unregister = null

  return {
    beforeMount() {

      unregister = MobileBackButtonHelper.addBeforeEach((to, from, next) => {
        console.log(`${this.$options.name} beforeEachFunc`)
        this[onCloseFunction]()
        next(false)
      })

      AndroidInterface.setFullscreen(true, () => {
        this[onCloseFunction]()
      })
    },
    beforeDestroy() {
      console.log(`${this.$options.name} mixin beforeDestroy`)
      unregister()
      AndroidInterface.setFullscreen(false)
    }
  }
}