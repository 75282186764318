const noRisk = 'no-risk'
const lowRisk = 'low-risk'
const mediumRisk = 'medium-risk'
const highRisk = 'high-risk'
const unknownRisk = 'unknown-risk'

const riskLabelsByValue = [
  {
    value: highRisk,
    text: "High Risk"
  },
  {
    value: mediumRisk,
    text: "Medium Risk"
  },
  {
    value: unknownRisk,
    text: "Unknown Risk"
  },
  {
    value: lowRisk,
    text: "Low Risk"
  },
  {
    value: noRisk,
    text: "No Risk"
  },
]

const riskByValue = [
  unknownRisk,
  noRisk,
  lowRisk,
  mediumRisk,
  highRisk
]

const riskLevels = {}
riskLevels[unknownRisk] = 0
riskLevels[noRisk] = 1
riskLevels[lowRisk] = 2
riskLevels[mediumRisk] = 3
riskLevels[highRisk] = 4

export {
  noRisk,
  lowRisk,
  mediumRisk,
  highRisk,
  unknownRisk,
  riskLevels,
  riskByValue,
  riskLabelsByValue
}