var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: true },
      model: {
        value: _vm.isVisible,
        callback: function($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "touch",
              rawName: "v-touch",
              value: {
                left: function() {
                  return _vm.swipeNext()
                },
                right: function() {
                  return _vm.swipePrevious()
                },
                up: function() {
                  return _vm.swipeNext()
                },
                down: function() {
                  return _vm.swipePrevious()
                }
              },
              expression:
                "{\n      left: () => swipeNext(),\n      right: () => swipePrevious(),\n      up: () => swipeNext(),\n      down: () => swipePrevious()\n    }"
            }
          ],
          staticClass: "fill-height",
          staticStyle: { background: "black", position: "relative" }
        },
        [
          _c(
            "v-toolbar",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hideInfo,
                  expression: "!hideInfo"
                }
              ],
              staticStyle: {
                position: "absolute",
                top: "0%",
                width: "100%",
                "z-index": "50"
              },
              attrs: { dense: "", flat: "", color: "rgba(0, 0, 0, 0.25)" }
            },
            [
              _c(
                "span",
                {
                  staticClass: "white--text subtitle-1",
                  staticStyle: { opacity: "0.75" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.currentIndex + 1 + " / " + _vm.images.length)
                  )
                ]
              ),
              _c("v-spacer"),
              !_vm.$store.state.inApp
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { opacity: "0.75" },
                      attrs: {
                        text: "",
                        dark: "",
                        disabled:
                          !_vm.$refs.photoGalleryImage ||
                          !_vm.$refs.photoGalleryImage.imageSource
                      },
                      on: { click: _vm.download }
                    },
                    [_c("v-icon", [_vm._v("cloud_download")])],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticStyle: { opacity: "0.75" },
                  attrs: { dark: "", text: "" },
                  on: { click: _vm.onClose }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _vm.showDelay
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  },
                  attrs: { id: "mainImageContainer" },
                  on: { click: _vm.toggleHideInfo }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "slide" } },
                    [
                      _c("encrypted-image", {
                        ref: "photoGalleryImage",
                        attrs: {
                          image: _vm.images[_vm.currentIndex],
                          "min-height": 50,
                          "account-id": _vm.accountId,
                          "device-id": _vm.deviceId
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-fade-transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hideInfo,
                    expression: "!hideInfo"
                  }
                ],
                staticStyle: { width: "100%", height: "100%" },
                attrs: { id: "controlsContainer" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticStyle: {
                      position: "absolute",
                      left: "2%",
                      top: "50%",
                      "z-index": "50",
                      opacity: "0.5"
                    },
                    attrs: { icon: "" },
                    on: { click: _vm.previous }
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-large": "", color: "accent" } },
                      [_vm._v("\n              chevron_left\n            ")]
                    )
                  ],
                  1
                ),
                _vm.images[_vm.currentIndex] &&
                _vm.images[_vm.currentIndex].encrypted
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          position: "absolute",
                          right: "1vw",
                          bottom: "1vh"
                        }
                      },
                      [_vm._v(_vm._s(_vm.keyEmoji))]
                    )
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "2%",
                      top: "50%",
                      "z-index": "50",
                      opacity: "0.5"
                    },
                    attrs: { icon: "" },
                    on: { click: _vm.next }
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-large": "", color: "accent" } },
                      [_vm._v("\n              chevron_right\n            ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          !_vm.hideInfo
            ? _c(
                "div",
                {
                  staticClass: "white--text caption pl-2",
                  staticStyle: {
                    position: "absolute",
                    bottom: "1%",
                    opacity: "0.5",
                    "word-wrap": "normal",
                    "text-overflow": "ellipsis",
                    background: "black",
                    width: "100%"
                  }
                },
                [
                  _c("v-fade-transition", [
                    _vm.images[_vm.currentIndex] &&
                    _vm.images[_vm.currentIndex].deviceName
                      ? _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.images[_vm.currentIndex].deviceName) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("v-fade-transition", [
                    _vm.appDetails && _vm.showDelay
                      ? _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.appDetails) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("v-fade-transition", [
                    _vm.appName && _vm.showDelay
                      ? _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.appName) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("v-fade-transition", [
                    _vm.images[_vm.currentIndex] &&
                    _vm.images[_vm.currentIndex].riskReason &&
                    _vm.showDelay
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "white-space": "nowrap",
                              overflow: "hidden",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.images[_vm.currentIndex].riskReason ||
                                    "notProvided"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm.images[_vm.currentIndex] &&
                  _vm.images[_vm.currentIndex].takenAt
                    ? _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.timestampString) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }