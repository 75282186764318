var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.state === _vm.states.decrypted || _vm.imageSource !== null
        ? [
            _c(
              "pinch-zoom",
              { ref: "pinchZoom", attrs: { disableZoomControl: "disable" } },
              [
                _c("v-img", {
                  class: {
                    "blur-high-risk":
                      _vm.$store.state.viewSettings &&
                      _vm.$store.state.viewSettings.blurHighRiskScreenshots &&
                      _vm.image.risk === "high-risk"
                  },
                  style:
                    "height: 98vh; width: 98vw; min-height: " +
                    _vm.minHeight +
                    "px;",
                  attrs: {
                    eager: _vm.eager,
                    contain: true,
                    src: _vm.imageSource
                  }
                })
              ],
              1
            )
          ]
        : _vm.state === _vm.states.error
        ? _c(
            "div",
            {
              staticClass: "text-center pa-3",
              style:
                "width: 100%; min-height: " +
                _vm.minHeight +
                "px; height: 100%; background: white;"
            },
            [
              _c("v-icon", { attrs: { color: "error" } }, [
                _vm._v("\n      warning\n    ")
              ]),
              _vm.decryptionError.status === "SIGNER_NOT_FOUND"
                ? [
                    _vm._v(
                      "\n      The encryption key used to sign this image was not found.\n    "
                    )
                  ]
                : _vm.decryptionError.message ===
                  "Recipient defined with id is not found within message info during data decryption."
                ? [
                    _vm._v(
                      "\n      The image was encrypted using an old encryption key.\n    "
                    )
                  ]
                : _vm.decryptionError.message ===
                  "Could not get public key from the second argument.Expected a Virgil Card or a Public Key object. Got boolean"
                ? [
                    _vm._v(
                      "\n      There was a decryption error.  Please refresh the page.  If the issue persists reach out to support@truple.io and indicate you have a duplicate e2ee card.\n    "
                    )
                  ]
                : [
                    _c("div", { staticClass: "pa-2" }, [
                      _vm._v(_vm._s(_vm.decryptionError.message))
                    ])
                  ],
              _c("div", { staticClass: "caption" }, [
                _vm._v(_vm._s("AccountId: " + _vm.accountId))
              ]),
              _c("div", { staticClass: "caption" }, [
                _vm._v(_vm._s("DeviceId: " + _vm.deviceId))
              ])
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }